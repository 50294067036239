.common_v3_nav_container {
  padding: 14px 0;
  background-color: var(--white);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .common_v3_nav_inner {
    max-width: var(--maxwidth);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .common_v3_nav_go_back {
      cursor: pointer;
      display: flex;
    }
    .common_v3_nav_logo {
      cursor: pointer;
      display: flex;
    }
    .no_show {
      visibility: hidden;
      display: flex;
    }
  }
}

.footer {
  width: 100%;
  padding: 0 20px 20px 20px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: var(--white);
  .footer_btn {
    @include f14;
    @include MB;
    color: var(--white);
    margin: 0 auto;
    background-color: var(--base);
    padding: 12px 20px;
    max-width: 440px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer_desc {
    max-width: 440px;
    @include f12;
    @include M;
    font-weight: 500;
    color: var(--base);
    margin: 20px auto 0 auto;
    text-align: center;
    > span {
      opacity: 0.7;
    }
    .readmore_btn {
      opacity: 1;
      cursor: pointer;
      text-decoration: underline;
      margin: 0 4px;
    }
  }
}
