*,
*:after,
*:before {
  box-sizing: border-box;
  // letter-spacing: 0.2px;
  // letter-spacing: 1px;
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
  print-color-adjust: exact !important;
}

@import "./common/1collect";
@import "./component/collection";
@import "./pages/collection";

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  color: var(--nero);
  margin: 0;
  overflow-x: hidden;
  --maxwidth: 480px;
  --ios_value_top: 0;
  --ios_value_bottom: 0;

  @include mobile {
    --ios_value_top: 0;
    --ios_value_bottom: 0;
  }
}
