.results_page {
  padding: 20px 0 0 0;
  // height: calc(100dvh - 44px);
  overflow: scroll;
  .results_page_inner {
    padding: 0 20px 0 20px;
    margin: 0 auto 0 auto;
    max-width: var(--maxwidth);
  }
  .results_page_title {
    @include f24;
    @include MB;
    text-align: center;
    color: var(--base);
    margin: 0 0 20px 0;
    span {
      text-decoration: underline;
    }
  }
  .results_page_box {
    border: 1px solid var(--gray-g-200);
    border-radius: 20px;
    padding: 27px 27px 15px 27px;
    margin: 0 0 20px 0;
    .results_page_title {
      @include f16;
      @include MB;
      color: var(--base);
      margin: 0 0 20px 0;
    }
    .results_page_spaceing {
      padding: 20px 0 0 0;
      margin: 0 0 20px 0;
    }
    .results_page_egg {
      background-color: var(--base);
      border-radius: 50%;
      height: 200px;
      aspect-ratio: 1;
      margin: 0 auto 20px auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--white);

      > div {
        @include f64;
        @include MB;
      }
      span {
        @include f24;
        @include MB;
      }
    }
    .results_page_live {
      @include f12;
      @include MEMM;
      text-align: center;
      color: var(--base);
      margin: 0 0 12px 0;
      text-transform: uppercase;
    }
  }
  .results_page_waiting {
    @include f20;
    @include MB;
    color: var(--base);
    margin: 20px 0;
  }
  .results_page_test_box {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 0px 3px 0px #0000000a, 0px 0px 6px 0px #00000008,
      0px 0px 8px 0px #00000005, 0px 0px 9px 0px #00000003,
      0px 0px 10px 0px #00000000;
    position: relative;
    .test_box_bg {
      background-image: url("https://cdn.sanity.io/images/nao51hyy/production/ce4f13440c26e8cc8fa42218092e4b53a953497a-200x200.png?w=2000&fit=max&auto=format");
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      height: 132px;
      width: 100%;
    }
    .test_box_bundle {
      position: absolute;
      top: 20px;
      left: 20px;
      background-color: var(--white);
      @include f14;
      @include M;
      line-height: 20px !important;
      padding: 4px 12px;
      border-radius: 360px;
      color: var(--base);
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .test_box_title {
      @include f20;
      @include MB;
      color: var(--base);
      padding: 20px 20px 16px 20px;
    }
    .test_box_desc {
      @include f14;
      @include M;
      color: var(--base);
      padding: 0 20px 16px 20px;
      opacity: 0.8;
    }
    .test_box_btn {
      @include f16;
      @include MB;
      color: var(--base);
      background-color: var(--merloat_light);
      border-radius: 100px;
      padding: 12px 20px;
      text-align: center;
      margin: 0 20px 20px 20px;
      cursor: pointer;
    }
  }
  .results_page_comm_title {
    @include f16;
    @include M;
    color: var(--base);
    opacity: 0.7;
    margin: 20px 0 10px 0;
  }
  .results_page_comm_box_header {
    display: flex;
    align-items: center;
    gap: 0 12px;
    margin: 0 0 20px 0;
    .comm_box_year {
      @include f14;
      @include M;
      width: calc((100% - 36px) / 4);
      text-align: center;
      border: 1px solid var(--gray-g-200);
      border-radius: 8px;
      padding: 16px;
      color: var(--base);
      cursor: pointer;
      white-space: nowrap;
      &[active="true"] {
        background-color: var(--base);
        color: var(--white);
        border: 1px solid var(--base);
      }
    }
  }
}
