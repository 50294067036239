// @import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Modern Era Bold WEB";
  src: url("./font/Modern_Era_Bold_WEB/ModernEra-Bold.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Medium WEB";
  src: url("./font/Modern_Era_Medium_WEB/ModernEra-Medium.woff") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono Medium WEB";
  src: url("./font/Modern_Era_Mono_Medium_WEB/ModernEraMono-Medium.woff")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modern Era Mono Regular WEB";
  src: url("./font/Modern_Era_Mono_Regular_WEB/ModernEraMono-Regular.woff")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "Modern Era Medium WEB";
}

@mixin M() {
  font-family: "Modern Era Medium WEB";
  line-height: 150% !important;
}
@mixin MB() {
  font-family: "Modern Era Bold WEB";
  line-height: 112% !important;
}
@mixin MEMM() {
  font-family: "Modern Era Mono Medium WEB";
}

@mixin MEMR() {
  font-family: "Modern Era Mono Regular WEB";
}

$const_font_weight: 400;

@mixin font_generator($font_size, $line_height, $weight) {
  --fs: #{desktop_length($font_size)};
  // font-weight: $weight;
  font-size: calc($font_size * 1px);
  @include mobile {
    --fs: #{mobile_length($font_size)};
    --lh: #{mobile_length($line_height)};
    font-size: calc($font_size * 1px);
  }
}

@mixin f64($weight: $const_font_weight) {
  @include font_generator(64, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f49($weight: $const_font_weight) {
  @include font_generator(49, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f36($weight: $const_font_weight) {
  @include font_generator(36, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f32($weight: $const_font_weight) {
  @include font_generator(32, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f30($weight: $const_font_weight) {
  @include font_generator(30, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f28($weight: $const_font_weight) {
  @include font_generator(28, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f28($weight: $const_font_weight) {
  @include font_generator(28, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f24($weight: $const_font_weight) {
  @include font_generator(24, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f22($weight: $const_font_weight) {
  @include font_generator(22, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f20($weight: $const_font_weight) {
  @include font_generator(20, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f18($weight: $const_font_weight) {
  @include font_generator(18, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f17($weight: $const_font_weight) {
  @include font_generator(17, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f16($weight: $const_font_weight) {
  @include font_generator(16, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f15($weight: $const_font_weight) {
  @include font_generator(15, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f14($weight: $const_font_weight) {
  @include font_generator(14, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f13($weight: $const_font_weight) {
  @include font_generator(13, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f12($weight: $const_font_weight) {
  @include font_generator(12, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f10($weight: $const_font_weight) {
  @include font_generator(10, 150, $weight);
  @include mobile {
    @content;
  }
}
@mixin f8($weight: $const_font_weight) {
  @include font_generator(8, 100, $weight);
  @include mobile {
    @content;
  }
}
