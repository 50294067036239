@function calc_percentage($curr, $og, $ref) {
  @return calc($curr * ($ref / $og));
}
.cust_input {
  --ref: 14;
  --c_ref: 16;
  --font_size: calc(var(--c_ref) * 1px);
  @include M;
  line-height: 18px !important;
  > .input_group_main {
    outline: calc_percentage(1px, var(--ref), var(--c_ref)) solid
      var(--gray-g-200);
    // border: 1px solid var(--gray-g-200);
    // outline: calc_percentage(1px, var(--ref), var(--c_ref)) solid var(--grey90);
    // outline: none !important;
    background-color: var(--white);
    border-radius: 5px;
    // overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus-within {
      outline: calc_percentage(2px, var(--ref), var(--c_ref)) solid
        var(--brand-peach-soda);
      // outline: calc_percentage(1px, var(--ref), var(--c_ref)) solid var(--gray85);
    }

    .input_prefix {
      display: flex;
      font-size: var(--font_size);
      background-color: transparent;
      svg {
        height: calc_percentage(18px, var(--ref), var(--c_ref));
        width: auto;
      }
    }
    > .input_group2 {
      width: 100%;
      position: relative;
      background-color: transparent;
      // font-family: inter !important;
      .cust_input_title {
        background: var(--white);
        outline: 5px solid var(--white);
        position: absolute;
        height: fit-content;
        top: calc(
          (calc_percentage(48px, var(--ref), var(--c_ref)) - var(--font_size)) /
            2
        );
        left: calc_percentage(11px, var(--ref), var(--c_ref));
        margin: auto 0;
        font-size: var(--font_size);
        line-height: 1;
        transform-origin: top left;
        transition: transform 0.2s linear, bottom 0.2s linear, top 0.2s linear,
          font-size 0.2s linear, top 0.2s linear;
        color: var(--grey);
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        // width: 100%;
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // padding: 0 10px 0 0;
        }
        &[active="true"] {
          top: calc_percentage(9px, var(--ref), var(--c_ref));
          top: 0;
          transform: translate(0, -50%);
          font-size: calc_percentage(12px, var(--ref), var(--c_ref));
        }
      }
      input::placeholder {
        // color: var(--light_black_2_5);
        color: var(--gray-g-500);
      }
      > input {
        // outline: none;
        background-color: transparent;
        border: 0;
        width: 100%;
        @include M;
        // &:focus-within {
        //   outline: none;
        // }
        &:read-only {
          // background-color: var(--gray-g-100);
          pointer-events: none !important;
        }

        color: var(--hague_blue);
        font-size: var(--font_size);
        height: calc_percentage(46px, var(--ref), var(--c_ref));
        outline: none;
        &.active_pad {
          // padding: calc_percentage(21px, var(--ref), var(--c_ref))
          //   calc_percentage(9px, var(--ref), var(--c_ref))
          //   calc_percentage(4px, var(--ref), var(--c_ref))
          //   calc(11px * var(--c_ref) / var(--ref));
          padding: calc_percentage(16.5px, var(--ref), var(--c_ref))
            calc_percentage(14px, var(--ref), var(--c_ref))
            calc_percentage(16.5px, var(--ref), var(--c_ref))
            calc(14px * var(--c_ref) / var(--ref));
        }
        &.unactive_pad {
          padding: 16px 10px 16px 12px;
        }

        &::placeholder {
          font-size: var(--font_size);
        }
        &:focus-visible ~ .cust_input_title {
          // top: calc_percentage(9px, var(--ref), var(--c_ref));
          top: 0;
          transform: translate(0, -50%);

          font-size: calc_percentage(12px, var(--ref), var(--c_ref));
        }
      }
    }
  }
  .input_error {
    overflow: hidden;
    color: red;
    font-size: var(--font_size);
    line-height: 1.3;
    padding: 0 0 0 0;
    height: 0;
    transition: height 0.3s linear;

    > div {
      padding: calc_percentage(5px, var(--ref), var(--c_ref)) 0 0 0;
    }
  }
  &.err_active {
    .input_group_main {
      outline: calc_percentage(1px, var(--ref), var(--c_ref)) solid var(--red);
    }
    .input_error {
      height: calc(
        calc_percentage(5px, var(--ref), var(--c_ref)) +
          (var(--font_size) * 1.3)
      );
    }
  }
  &.with_prefix {
    .input_prefix,
    .input_suffix {
      //   color: var(--grey);
      color: #aaaaaa;
      @include f16;
      @include M;
      font-weight: 400;
      line-height: 20px; /* 125% */
      // letter-spacing: -0.16px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .input_prefix {
      margin: 0 calc_percentage(3.75px, var(--ref), var(--c_ref)) 0
        calc_percentage(11px, var(--ref), var(--c_ref));
      align-self: stretch;
    }
    .input_suffix {
      margin: 0 calc_percentage(11px, var(--ref), var(--c_ref)) 0 0;
    }
  }
  &.readonly {
    > .input_group_main {
      background: #310e0d17;
      .cust_input_title {
        background: transparent;
        outline: 0;
      }
    }
    // opacity: 0.4;
  }
}
.cust_phone_input_main {
  .cust_input_title {
    transition: transform 0.2s linear, bottom 0.2s linear, top 0.2s linear,
      font-size 0.2s linear, top 0.2s linear, left 0.2s linear !important;
    &[active="true"] {
      left: -72px !important;
    }
  }
  input:focus-visible {
    ~ .cust_input_title {
      left: -72px !important;
    }
  }
}
