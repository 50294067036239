.form_page_main {
  .common_v3_nav_container {
    padding: 34px 0 14px 0;
  }
  .form_page_section {
    --footer_height: 160px;
    padding: 20px 0 0 0;
    // height: calc(100dvh - (64px + var(--footer_height)));
    overflow: scroll;
    .form_page_section_inner {
      padding: 0 20px 0 20px;
      margin: 0 auto 0 auto;
      max-width: var(--maxwidth);
    }
    .form_inputs {
      margin: 0 0 20px 0;
      position: relative;
      .form_inputs_title {
        @include f16;
        @include MB;
        color: var(--base);
        margin: 0 0 11px 0;
      }
      .form_inputs_box {
        display: flex;
        align-items: center;
        gap: 0 12px;
      }
      .form_select {
        padding: 16px 12px;
        border: 1px solid var(--gray-g-200);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 6px;
        @include f16;
        @include M;
        line-height: 18px !important;
        cursor: pointer;
        &.error {
          border: 1px solid var(--red);
        }
        span {
          color: var(--gray-g-350);
        }
        > div {
          display: flex;
          svg {
            transition: all 0.2s linear;
          }
        }
      }
      .form_select_options {
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border: 1px solid var(--grey90);
        border-radius: 8px;
        position: absolute;
        top: calc(100% + 4px);
        z-index: 100;
        width: 100%;
        overflow: hidden;
        > div {
          padding: 8px 16px;
          cursor: pointer;
          @include f14;
          @include M;
          color: var(--base);

          // &:hover {
          //   background-color: var(--gray-g-200);
          // }
          &[active="true"] {
            background-color: var(--base);
            color: var(--white);
          }
        }
      }
    }
    .form_question {
      position: relative;
      .form_inputs_title {
        @include f16;
        @include MB;
        color: var(--base);
        margin: 0 0 12px 0;
      }
      .form_inputs_desc {
        @include f14;
        @include M;
        color: var(--base);
        opacity: 0.7;
      }
      .form_box {
        display: flex;
        align-items: center;
        gap: 0 12px;
        margin: 12px 0 0 0;
        > div {
          width: 50%;
          padding: 16px 12px;
          color: var(--grey);
          border: 1px solid var(--gray-g-200);
          border-radius: 8px;
          cursor: pointer;
          @include f16;
          @include M;
          line-height: 18px !important;
          &.active_btn {
            background-color: var(--base);
            border: 1px solid var(--base);
            color: var(--white);
          }
        }
      }
      .form_error {
        display: none;
        margin: 5px 0 0 0;
        &.active {
          display: block;
          color: var(--red);
        }
      }
      .form_checkbox_inputs {
        display: flex;
        flex-direction: column;
      }
    }
    .selection_y_n {
      margin: 20px 0 0 0;
    }
    .form_test_box {
      display: flex;
      align-items: center;
      gap: 20px;
      background-color: var(--milk-white);
      padding: 8px;
      border-radius: 8px;
      margin: 12px 0 0 0;
      cursor: pointer;
      .form_test_box_img {
        width: 48px;
        aspect-ratio: 1;
        border-radius: 4px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .form_test_box_detail {
        .form_test_box_detail_title {
          @include f16;
          @include MB;
          color: var(--base);
          margin: 0 0 2px 0;
        }
        .form_test_box_detail_status {
          @include f14;
          @include M;
          color: var(--base);
          opacity: 0.7;
        }
      }
      .form_test_box_svg {
        margin: 0 0 0 auto;
      }
    }
    .form_check_boxs {
      margin: 20px 0 20px 0;
      .form_checkbox_inputs {
        display: flex;
        gap: 12px;
        @include mobile480 {
          flex-direction: column;
        }

        > div {
          padding: 16px 12px;
          border: 1px solid var(--gray-g-200);
          border-radius: 6px;
          cursor: pointer;
          @include f16;
          @include M;
          line-height: 18px !important;
          color: var(--base);
          display: flex;
          align-items: center;
          position: relative;
          width: calc((100% - 12px) / 2);
          @include mobile480 {
            width: 100%;
          }
          .checkbox_input_info {
            margin: 0 0 0 auto;
            display: flex;
          }
          .checkbox {
            margin: 0 8px 0 0;
            border-radius: 2px;
          }
          .tooltip {
            position: absolute;
            background-color: #333;
            color: #fff;
            padding: 5px 10px;
            border-radius: 4px;
            @include f12;
            @include M;
            bottom: 75%;
            right: 5%;
            // transform: translateX(-10%);
            z-index: 1;
            max-width: 100%;
            @include mobile480{
              max-width: 80%;
            }
          }
        }
      }
      .form_error {
        display: none;
        margin: 5px 0 0 0;
        &.active {
          display: block;
          color: var(--red);
        }
      }
    }
  }
}
