.cover_main {
  background-color: var(--base);

  .common_v3_nav_container {
    padding: 34px 0 14px 0;
    background-color: var(--base);
    .common_v3_nav_inner {
      .common_v3_nav_logo {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
  .cover_section {
    height: calc(100dvh - (64px + 68px));
    overflow-y: scroll;
    display: flex;
    > div {
      margin: auto;
      padding: 20px;
      max-width: var(--maxwidth);
    }
    .cover_section_chip {
      background-color: var(--white);
      border-radius: 1000px;
      padding: 6px 12px;
      color: var(--base);
      @include f14;
      font-weight: 500;
      @include M;
      margin: 0 auto 20px auto;
      width: fit-content;
    }
    .cover_section_title {
      @include f30;
      @include M;
      font-weight: 700;
      color: var(--white);
      margin: 0 0 20px 0;
      text-align: center;
    }
    .cover_section_desc {
      @include f16;
      @include M;
      font-weight: 500;
      color: var(--white);
      text-align: center;
      opacity: 0.7;
    }
  }
  .footer {
    background-color: var(--base);
    .footer_btn {
      background-color: var(--white);
      color: var(--base);
    }
  }
}
