.svg_floater_main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  svg {
    animation: rotate_loader 2.5s infinite linear;
    @keyframes rotate_loader {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
.loader {
  height: 100dvh;
  display: flex;
  position: fixed;
  inset: 0;
  z-index: 1000;
  .loader_main {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: var(--white);
    @include M;
    animation: loading 2s forwards linear;
    .svg_floater_main {
      position: relative;
      margin: 0 0 21px 0;
      height: unset;
      width: unset;
      svg {
        width: 100%;
        height: auto;
      }
    }
    @keyframes loading {
      0%,
      99% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
    .txt1 {
      @include f14(700);
      @include MB;
      color: var(--base);
    }
    .txt2 {
      @include f14;
      color: var(--grey33);
    }
  }
}
