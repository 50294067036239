:root {
  --black: #000;
  --white: #fff;
  --basewhite: #ffefef;
  // --base: #852f00;
  --base: #5f1d1b;
  // --base_light: #89302e;
  --base_light: #5f1d1b;
  --merloat_light: #fff2ee;
  --brand-peach-soda: #ffa977;
  --accent-red-100: #ff5c5c;
  --brand-banana-ice-cream: #ffe7bf;
  --brand-banana-ice-cream2: #fffbf2;
  --baseOpas: #f5f5f5;
  --hague_blue: #333333;
  --nero: #1d1d1d;
  --grey99: #fafafa71;
  --grey98: #fafafa;
  --grey90: #e5e5e5;
  --grey33: #545454;
  --gray85: #d9d9d9;
  --gray500: #454545;
  --gray-g-200: #e5e5e5;
  --gray-g-350: #aaaaaa;

  --grey: #777777;
  --gray-g-500: #adadad;
  --gray-g-300: #c5c5c5;
  --gray-g-100: #f1f1f1;
  --red: #dd1d1d;
  --whitesmoke: #f5f5f5;
  --ligntwhite: #fafbfb;
  --lightgreen: #aee9d1;
  --lightblue: #a4e8f2;
  --gray111: #babfc3;
  --graim: #c9cccf;
  --nickel: #6d7175;
  --tropical_rain_forest: #008060;
  --accent-green-000: #3a7d23;
  --accent-green-001: #397d2377;
  --accent-green-100: #64be45;
  --dazzling_red: #d82c0d;
  --accent-green-200: #eff8ec;
  --milk-white: #fffbf2;
  --ocean: #2b3a33;
  --ocean_light: #d4dbcb;
  --ocean_light1: #f3f2e7;
}
