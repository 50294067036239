.checkbox {
  width: 14px;
  height: 14px;
  border: 1px solid var(--gray-g-200);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
}

.checked {
  color: white;
  background-color: var(--base);
  svg {
    width: 100%;
    height: auto;
    g {
      path {
        fill: var(--white);
      }
    }
  }
}
